import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20')
];

export const server_loads = [];

export const dictionary = {
		"/(authed)": [5,[2]],
		"/(authed)/brand/[brand_uuid=uuid]": [6,[2,3],[,4]],
		"/(authed)/brand/[brand_uuid=uuid]/assets": [8,[2,3],[,4]],
		"/(authed)/brand/[brand_uuid=uuid]/branding": [9,[2,3],[,4]],
		"/(authed)/brand/[brand_uuid=uuid]/campaigns": [10,[2,3],[,4]],
		"/(authed)/brand/[brand_uuid=uuid]/collections": [11,[2,3],[,4]],
		"/(authed)/brand/[brand_uuid=uuid]/control-panel": [12,[2,3],[,4]],
		"/(authed)/brand/[brand_uuid=uuid]/dashboard": [13,[2,3],[,4]],
		"/(authed)/brand/[brand_uuid=uuid]/dipp-admin-panel": [14,[2,3],[,4]],
		"/(authed)/brand/[brand_uuid=uuid]/layouts": [15,[2,3],[,4]],
		"/(authed)/brand/[brand_uuid=uuid]/settings": [16,[2,3],[,4]],
		"/(authed)/brand/[brand_uuid=uuid]/shared-links": [17,[2,3],[,4]],
		"/(authed)/brand/[brand_uuid=uuid]/[...path]": [7,[2,3],[,4]],
		"/(public)/gallery": [19],
		"/(public)/login": [20],
		"/(authed)/select-brand": [18,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.js';